<template>
  <router-view/>
</template>

<style lang="scss">
@import "@/assets/style/base";
@import "@/assets/style/colors";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $dark;
  min-height: 100vh;
}
</style>
