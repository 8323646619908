<template>
  <div class="center">
    <img src="@/assets/images/mundi-capital.png" alt="Mundi Capital" />
    <button-box href="https://cliente.livework.com.vc/login">Acesse seu Banco de Dados</button-box>
    <icon-box href="tel:+5547992255126" icon="phone">(47) 99225-5126</icon-box>
    <icon-box href="mailto:mundi@mundicapital.com.br" icon="email">mundi@mundicapital.com.br</icon-box>
    <icon-box href="https://goo.gl/maps/vHdApB9xmkmDNHwk6" icon="map">Rua José Marangoni, 330, Sala 01<br/>Vila Nova, Jaraguá do Sul / SC</icon-box>
    <button-box href="mailto:mundi@mundicapital.com.br">Envie-nos uma mensagem</button-box>
  </div>
</template>

<script>
import ButtonBox from '@/components/ButtonBox.vue'
import IconBox from '@/components/IconBox.vue'

export default {
  name: 'HomeView',
  components: {
    ButtonBox,
    IconBox
  }
}
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  padding: 40px 10px;
  max-width: 420px;
  min-height: 100vh;

  img {
    box-shadow: 3px 3px 1px 0 rgba(0,0,0,.3);
  }
}
</style>
